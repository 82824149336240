<template>
  <div id="added-services" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <b-table :data="addedServices" :paginated="isPaginated" hoverable>
        <b-table-column field="#" label="#" width="40" numeric v-slot="props">
          {{ addedServices.indexOf(props.row) + 1 }}
        </b-table-column>

        <b-table-column label="Name" v-slot="props">{{
          props.row.name
        }}</b-table-column>

        <b-table-column label="Amount" v-slot="props">{{
          props.row.amount
        }}</b-table-column>

        <b-table-column label="Actions" width="160" v-slot="props">
          <action-buttons
            @initialize-info="initializeInfo(props.row)"
            @initialize-update="initializeUpdate(props.row)"
            @initialize-remove="initializeRemove(props.row)"
          />
        </b-table-column>
      </b-table>
    </template>
    <!-- Delete -->
    <app-modal
      id="remove-added-service"
      context="remove"
      @remove="remove"
      :show-modal.sync="isModalOpen"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { crudNotification } from '../../assets/js/notification'

export default {
  data() {
    return {
      schoolId: null,
      pageTitle: 'Added Services',
      addedServices: [],
      isPaginated: true,
      id: null,
    }
  },
  methods: {
    initializeInfo(added_service) {
      // this.$router.push(
      //   `/school/${this.schoolId}/added_service_info/${added_service.id}`
      // )
    },
    initializeUpdate(added_service) {
      this.$router.push(
        `/school/${this.schoolId}/added_service/${added_service.id}`
      )
    },
    initializeRemove(added_service) {
      this.id = parseInt(added_service.id)
      this.openModal()
    },
    remove() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation DeleteAddedService($id: Int!) {
              deleteAddedService(input: { id: $id }) {
                addedService {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: this.id,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.deleteAddedService.errors,
            'Successfully deleted.'
          ).then(() => {
            this.closeModal()
            this.$apollo.queries.addedServices.refetch()
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Fee Management',
        route: `/school/${this.schoolId}/fee_management`,
      },
      {
        name: 'New',
        route: `/school/${this.$route.params.school_id}/added_service/new`,
      },
    ])

    this.$apollo.addSmartQuery('addedServices', {
      query: gql`
        query AddedServicesQuery($schoolId: ID!) {
          addedServices(schoolId: $schoolId) {
            id
            name
            amount
          }
        }
      `,
      variables: {
        schoolId: this.schoolId,
      },
    })

    this.$apollo.queries.addedServices.refetch()
  },
}
</script>
